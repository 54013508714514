import { ApolloClient, from, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { toastController } from '@ionic/vue';
import i18n from '@/plugins/i18n';
import AuthService from '@/services/Auth';

// Set authorization headers
const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Forwarded-Host': AuthService.getHost(),
  },
}));
const authCustomerLink = setContext(async (_, { headers }) => {
  const token = await AuthService.getToken();

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

/**
 * Function for showing error toast.
 *
 * @param {String} message
 */
async function showErrorToast(message) {
  const toast = await toastController.create({
    message,
    duration: 2000,
    color: 'danger',
  });
  await toast.present();
}

// Attach error handler
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const first = graphQLErrors[0];
    let message;
    if (first.extensions.category === 'validation') {
      message = Object.values(first.extensions.validation)[0].join(', ');
    } else if (first.debugMessage) {
      message = first.debugMessage;
    } else if (first.path[0] !== 'RegisterCustomerDevice') {
      message = first.message;
    }

    if (message) {
      showErrorToast(message);
    }
  }

  if (networkError) {
    showErrorToast(i18n.global.t('errors.network'));
  }
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    AppconfigType: {
      keyFields: ['appid'],
    },
    OccasionFinancingType: {
      keyFields: ['url'],
    },
  },
});

// Default options
const defaultOptions = {
  query: {
    fetchPolicy: process.env.NODE_ENV === 'test' ? 'no-cache' : 'cache-first',
  },
};

// Create the apollo client
const apolloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    createUploadLink({ uri: process.env.VUE_APP_API_URL }),
  ]),
  cache,
  defaultOptions,
});

// Create the apollo customer client
const apolloCustomerClient = new ApolloClient({
  link: from([
    authCustomerLink,
    errorLink,
    createUploadLink({ uri: process.env.VUE_APP_CUSTOMER_API_URL }),
  ]),
  cache,
  defaultOptions,
});

export { apolloClient, apolloCustomerClient, cache };
