import { reactive } from 'vue';

export default reactive({
  config: {},
  organisation: {},
  leadProviderId: null,
  locations: [],
  user: {
    customer: {
      data: [
        { key: 'name', value: null },
      ],
      vehicles: [],
    },
  },
  extra_fields: [],
  start: {},
  messages: null,
});
