<template>
  <ion-img
    :src="`https://storage.googleapis.com/merk_logos/${brand}.jpg`"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { IonImg } from '@ionic/vue';

export default defineComponent({
  components: {
    IonImg,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    brand() {
      return this.vehicle.brand.toLowerCase().replaceAll(' ', '-');
    },
  },
});
</script>
