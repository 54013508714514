<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { defineComponent } from 'vue';
import { useState } from 'vue-gtag-next';
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue';
import EventBus from '@/eventBus';
import store from '@/store';
import i18n from '@/plugins/i18n';
import AppService from '@/services/App';

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet,
  },
  computed: {
    config: () => store.config,
    organisation: () => store.organisation,
  },
  async created() {
    window.addEventListener('beforeinstallprompt', async (e) => {
      e.preventDefault();
      const toast = await toastController.create({
        message: 'Add Harold Arendsen to Home screen',
        buttons: [
          {
            side: 'end',
            text: 'Install',
            handler: e.install,
          },
        ],
        color: 'primary',
      });
      await toast.present();
    });

    EventBus.on('sw', async (state) => {
      if (state === 'updated') {
        const toast = await toastController.create({
          message: i18n.global.t('general.update'),
          buttons: [
            {
              side: 'end',
              text: i18n.global.t('general.refresh'),
              /* c8 ignore next 5 */
              handler: async () => {
                const registrations = await navigator.serviceWorker.getRegistrations();
                await Promise.all(registrations.map((r) => r.unregister()));
                window.location.reload(true);
              },
            },
          ],
          color: 'primary',
        });
        await toast.present();
      }
    });

    await AppService.getConfig();

    // Set theme
    const root = document.documentElement;
    root.style.setProperty('--ion-background-color', this.config.header_color);
    root.style.setProperty('--ion-color-secondary', this.config.header_color);
    root.style.setProperty('--ion-color-secondary-contrast', this.config.header_text_color);
    root.style.setProperty('--ion-color-primary', this.config.button_color);
    root.style.setProperty('--ion-color-primary-contrast', this.config.button_text_color);
    root.style.setProperty('--start-background', this.config.background_color);
    root.style.setProperty('--start-color', this.config.background_text_color);

    // Set icon
    const icon = document.createElement('link');
    icon.rel = 'apple-touch-icon';
    icon.href = this.config.appicon;
    document.head.appendChild(icon);

    // Set title
    document.title = this.organisation.name;

    // Set analytics
    const { property } = useState();
    property.value = {
      id: this.config.google_analytics_4_code ?? this.config.google_analytics_code,
      params: {
        anonymize_ip: true,
      },
    };

    /* c8 ignore start */

    // Load FB SDK
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this.config.facebook_id,
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v11.0', // use graph api current version
      });
    };

    // Load the SDK asynchronously
    (function fbSdk(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (!fjs || d.getElementById(id)) return;
      const js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },
});
</script>
