import { Capacitor } from '@capacitor/core';
import _clonedeep from 'lodash.clonedeep';
import { getToken } from 'firebase/messaging';
import { apolloClient, apolloCustomerClient } from '@/plugins/apollo';
import { messaging } from '@/plugins/firebase';
import { changeLocale } from '@/plugins/i18n';
import AuthService from '@/services/Auth';
import customerRegisterDeviceMutation from '@/graphql/customer/registerDevice.gql';
import appleLoginQuery from '@/graphql/login/apple.gql';
import facebookLoginQuery from '@/graphql/login/facebook.gql';
import emailLoginQuery from '@/graphql/login/email.gql';
import loginQuery from '@/graphql/login/login.gql';
import meQuery from '@/graphql/customer/me.gql';
import profilePictureMutation from '@/graphql/customer/profilePicture.gql';
import updateMutation from '@/graphql/customer/update.gql';
import updateBoardedMutation from '@/graphql/customer/updateBoarded.gql';
import updateLocationMutation from '@/graphql/customer/updateLocation.gql';
import updatePreferencesMutation from '@/graphql/customer/updatePreferences.gql';
import deleteMutation from '@/graphql/customer/delete.gql';
import store from '@/store';

export default {
  /**
   * Log in with apple.
   *
   * @param {Object} variables
   *
   * @returns {Object}
   */
  async loginWithApple(variables = {}) {
    const response = await apolloClient.query({
      query: appleLoginQuery,
      variables,
    });

    // Set token to storage
    if (response.data.appleLogin.token) {
      await AuthService.setToken(response.data.appleLogin.token);
    }

    return response.data.appleLogin;
  },
  /**
   * Log in with facebook.
   *
   * @param {String} email
   * @param {String} code
   *
   * @returns {Object}
   */
  async loginWithFacebook(token) {
    const response = await apolloClient.query({
      query: facebookLoginQuery,
      variables: {
        token,
      },
    });

    // Set token to storage
    if (response.data.facebookLogin.token) {
      await AuthService.setToken(response.data.facebookLogin.token);
    }

    return response.data.facebookLogin;
  },
  /**
   * Request login code.
   *
   * @param {String} email
   *
   * @returns {Boolean}
   */
  async requestCode(email) {
    const response = await apolloClient.mutate({
      mutation: emailLoginQuery,
      variables: {
        email,
      },
    });

    return response.data.customerRestore;
  },
  /**
   * Log in with code.
   *
   * @param {String} email
   * @param {String} code
   *
   * @returns {Object}
   */
  async loginWithCode(email, code) {
    const response = await apolloClient.query({
      query: loginQuery,
      variables: {
        email,
        code,
      },
    });

    // Set token to storage
    if (response.data.login.token) {
      await AuthService.setToken(response.data.login.token);
    }

    return response.data.login;
  },
  /**
   * Register device.
   *
   * @return {Object}
   */
  async registerDevice() {
    try {
      const token = await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });

      const response = await apolloCustomerClient.mutate({
        mutation: customerRegisterDeviceMutation,
        variables: {
          token,
          device_type: Capacitor.getPlatform(),
        },
      });

      return response.data.RegisterCustomerDevice;
    } catch {
      /* c8 ignore next 2 */
      return false;
    }
  },
  /**
   * Get profile.
   *
   * @param {String} fetchPolicy
   *
   * @returns {Object}
   */
  async getProfile(fetchPolicy = 'network-only') {
    const response = await apolloCustomerClient.query({
      query: meQuery,
      fetchPolicy,
    });

    const user = _clonedeep(response.data.me);

    /* c8 ignore next 3 */
    if (!user) {
      return null;
    }

    // Map customer data for easier use
    const data = [...user.customer.data];
    user.customer.data = {};
    data.forEach((item) => {
      user.customer.data[item.key] = item.value;
    });

    store.user = user;

    const customerFields = _clonedeep(response.data.customerFields);
    store.extra_fields = customerFields.filter((field) => ['TEXT', 'NUMBER', 'DATE'].includes(field.classification));

    if (user.customer.data.language) {
      changeLocale(user.customer.data.language);
    }

    return user;
  },
  /**
   * Upload picture.
   *
   * @param {File} file
   *
   * @returns {Object}
   */
  async uploadPicture(file) {
    const response = await apolloCustomerClient.mutate({
      mutation: profilePictureMutation,
      variables: {
        file,
      },
    });

    Object.assign(store.user.customer, response.data.customer);

    return store.user;
  },
  /**
   * Update boarded.
   *
   * @returns {Object}
   */
  async updateBoarded() {
    const response = await apolloCustomerClient.mutate({
      mutation: updateBoardedMutation,
    });

    store.user.onboarding_completed = response.data.onboardingCompleted;

    return store.user;
  },
  /**
   * Update profile.
   *
   * @param {Array} values
   *
   * @returns {Object}
   */
  async updateProfile(values) {
    const data = values.filter((d) => d.value);

    if (data.length) {
      const response = await apolloCustomerClient.mutate({
        mutation: updateMutation,
        variables: {
          data,
        },
      });

      Object.assign(store.user.customer, response.data.customer);
    }

    return store.user;
  },
  /**
   * Update location.
   *
   * @param {Number} id
   *
   * @returns {Object}
   */
  async updateLocation(id) {
    const response = await apolloCustomerClient.mutate({
      mutation: updateLocationMutation,
      variables: {
        id,
      },
    });

    store.user.customer.default_location_id = response.data.SetDefaultLocation;

    return store.user;
  },
  /**
   * Update preferences.
   *
   * @param {Object} preferences
   *
   * @returns {Object}
   */
  async updatePreferences(preferences) {
    const response = await apolloCustomerClient.mutate({
      mutation: updatePreferencesMutation,
      variables: {
        preferences,
      },
    });

    store.user.customer.preferences = response.data.UpdatePreferences;

    return store.user;
  },
  /**
   * Delete account.
   *
   * @returns {Boolean}
   */
  async delete() {
    const response = await apolloCustomerClient.mutate({
      mutation: deleteMutation,
    });

    return response.data.deleteAccount;
  },
};
