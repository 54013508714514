import { apolloClient } from '@/plugins/apollo';
import configQuery from '@/graphql/config.gql';
import LeadsService from '@/services/Leads';
import store from '@/store';

const leadProviderName = 'PWA';

export default {
  /**
   * Get app config.
   *
   * @returns {Object}
   */
  async getConfig() {
    const response = await apolloClient.query({
      query: configQuery,
    });

    store.config = response.data.appconfig;
    store.organisation = response.data.organisation;
    store.leadProviderId = response.data.leadProviders.find((p) => p.name === leadProviderName)?.id;

    // Create lead provider, if not any yet
    if (!store.leadProviderId) {
      const leadProvider = await LeadsService.createProvider({
        leadProvider: {
          name: leadProviderName,
        },
      });
      store.leadProviderId = leadProvider.id;
    }

    return store.config;
  },
};
