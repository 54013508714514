export default {
  general: {
    add: 'Toevoegen',
    appointment: 'Afspraak maken',
    search: 'Zoeken',
    search_heading: 'Zoekresultaat',
    loading: 'Even geduld aub...',
    error: 'Er is een fout opgetreden: {message}',
    cancel: 'Annuleren',
    vehicle: 'Auto',
    location: 'Vestiging',
    type: 'Type afspraak',
    replacement_vehicle: 'Vervangend vervoer',
    mileage: 'Kilometerstand',
    build_year: 'Bouwjaar',
    transmission: 'Transmissie',
    date: 'Datum',
    time: 'Tijd',
    remarks: 'Opmerking',
    contact: 'Contact opnemen',
    next: 'Ga verder',
    prev: 'Ga terug',
    choice: 'Maak een keuze',
    no_preference: 'Geen voorkeur',
    automatic: 'Automaat',
    manual: 'Handgeschakeld',
    save: 'Opslaan',
    of: 'van',
    failure: 'Pech onderweg?',
    back: 'Terug',
    my_data: 'Mijn gegevens',
    my_data_description: 'Om je zo goed mogelijk van dienst te kunnen zijn vragen we je om onderstaande gegevens te controleren of waar nodig aan te vullen.',
    first_name: 'Voornaam',
    preposition: 'Tussenvoegsel',
    last_name: 'Achternaam',
    email: 'E-mailadres',
    phone: 'Telefoonnummer',
    address: 'Straatnaam, huisnummer en toevoeging',
    postcode_city: 'Postcode en woonplaats',
    date_time: 'Datum en tijd',
    license_plate: 'Kenteken',
    birthdate: 'Geboortedatum',
    country: 'Land',
    country_nl: 'Nederland',
    country_be: 'België',
    country_de: 'Duitsland',
    country_lu: 'Luxemburg',
    language: 'Taal',
    default_location: 'Voorkeursvestiging',
    update: 'Er is een update beschikbaar.',
    refresh: 'Vernieuwen',
  },
  login: {
    title: 'Log in om gebruik te maken onze (web)app.',
    apple: 'Inloggen met Apple',
    facebook: 'Inloggen met Facebook',
    email: 'Inloggen met e-mail',
    continue: 'Doorgaan zonder inloggen',
    email_description: 'Vul je e-mailadres in, je ontvangt een code waarmee je kan inloggen.',
    send_code: 'Stuur code',
    received_code: 'Vul de code in die je per e-mail hebt ontvangen op',
    login: 'Inloggen',
  },
  onboarding: {
    welcome: 'Welkom',
    intro: 'Om je zo goed mogelijk van dienst te kunnen zijn vragen we je om onderstaande gegevens te controleren of waar nodig aan te vullen.',
    address: 'Ben je nog steeds op dit adres woonachtig?',
    contact: 'We zijn er bijna. Hoe kunnen we je bereiken?',
    cars: 'Heb je onderstaande auto\'s nog in je bezit?',
    no_cars: 'Er zijn geen voertuigen bij ons bekend. Registreer uw voertuig via onderstaande button.',
    continue: 'Ga door',
    skip: 'Ik vul dit later in',
    agree: 'Ik ga akkoord',
    disagree: 'Ik ga niet akkoord',
    privacy: 'Om gebruik te kunnen maken van de functionaliteiten van onze APP dient u akkoord te gaan met het <a href="https://autosociaal.nl/wp-content/uploads/2022/05/Privacy-Statement-2022_0605.pdf" target="_blank">privacystatement</a>.',
    logout: 'Uitloggen',
    delete: 'Uitloggen en verwijder mijn gegevens',
  },
  start: {
    title: 'Start',
    welcome: 'Welkom',
    unread_messages: ({ named, plural }) => `Je hebt ${named('n')} ongelezen ${plural(['bericht', 'berichten'])}`,
    unread_notifications: ({ named, plural }) => `Je hebt ${named('n')} ongelezen ${plural(['notificatie', 'notificaties'])}`,
    message_received: 'Op {date} ontvangen',
    workshop_appointment: 'Werkplaatsafspraak',
    appointment_location: '{date} in {location}',
    your_vehicles: 'Jouw voertuigen',
  },
  messages: {
    title: 'Berichten',
    camera: 'Camera',
    photos: 'Foto toevoegen',
    videos: 'Video toevoegen',
    pdf: 'PDF toevoegen',
    location: 'Locatie sturen',
    chat: 'Chat',
    notifications: 'Notificaties',
    open: 'Open de chat',
  },
  appointment: {
    title: 'Afspraak',
    view: 'Afspraak bekijken',
    workshop: 'Werkplaatsafspraak',
    description: 'Je hebt voor jouw {vehicle} een {activity}',
    location: 'op {date} in {location}',
    status: {
      awaiting_reaction: 'aangevraagd',
      accepted: 'ingepland',
      rejected: 'afgewezen',
      edited: 'bewerkt',
      externally_managed: 'aangevraagd',
    },
    agenda: 'Zet in agenda',
    edit: 'Afspraak wijzigen',
    details: 'Afspraak details',
    contact: 'Neem contact op met de vestiging om uw afspraak te wijzigen',
    vehicle: 'Om welke auto gaat het?',
    establishment: 'Bij welke vestiging?',
    specialty: 'Bij welk merk?',
    activities: 'Extra werkzaamheden',
    date: 'Kies een datum',
    time: 'Kies een tijd',
    plan: 'Inplannen',
  },
  contact: {
    title: 'Contact',
    establishment: 'Kies een vestiging',
    location: 'Vestiging',
    message: 'Stuur een bericht',
    call: 'Bel naar {phone}',
    email: 'E-mail {email}',
    website: 'Ga naar onze website',
    opening_hours: 'Openingstijden',
    workshop: 'Werkplaats',
    general: 'Algemeen',
    sales: 'Verkoop',
    reception: 'Receptie',
    administration: 'Administratie',
    planner: 'Planner',
  },
  more: {
    title: 'Meer',
    logout: 'Uitloggen',
  },
  occasions: {
    title: 'Aanbod',
    filter: 'Aanbod filteren',
    brand_filter: 'Filter op merk',
    model_filter: 'Filter op model',
    advanced_filter: 'Uitgebreid filteren',
    results: '1 resultaat | {n} resultaten',
    financial: 'of vanaf € {price},- p.m.',
    sold: 'Verkocht',
    more: 'Laad meer occasions',
    build_year_from: 'Bouwjaar van',
    build_year_till: 'Bouwjaar tot',
    price_from: 'Prijs van',
    price_till: 'Prijs tot',
    mileage_from: 'KM-stand van',
    mileage_till: 'KM-stand tot',
    video: 'Video',
    image: 'Afbeelding',
    pictures: 'Foto\'s',
    videos: 'Video\'s',
    pictures360: '360° foto\'s',
    intro: 'Intro',
    specs: 'Specificaties',
    description: 'Omschrijving',
    other: 'Overig',
    price: 'Prijs',
    finance: 'Financiering',
    lease: 'Vanaf € {price},- p/m',
    brand: 'Merk',
    model: 'Model',
    type: 'Uitvoering',
    apk_delivery: 'APK bij oplevering',
    tax: 'BTW/marge',
    cylinders: 'Aantal cilinders',
    bodywork: 'Carosserievorm',
    doors: 'Deuren',
    color: 'Kleur',
    weight: 'Gewicht',
    cylinder_capacity: 'Cilinderinhoud',
    power: 'Max vermogen',
    fuel: 'Brandstof',
    request_quote: 'Vraag een offerte aan',
    calculate_financing: 'Financiering berekenen',
    request_test_drive: 'Vraag een proefrit aan',
    determine_trade_in_value: 'Bepaal inruilwaarde',
    request_test_drive_thanks: 'Bedankt voor het aanvragen van een proefrit. We nemen zo snel mogelijk contact met je op.',
    trade: 'Inruilen',
    request_quote_thanks: 'Bedankt voor het aanvragen van een offerte. We nemen zo snel mogelijk contact met je op.',
    determine_trade_in_value_thanks: 'Bedankt voor het aanvragen van een inruilwaardebepaling. We nemen zo snel mogelijk contact met je op.',
  },
  news: {
    title: 'Nieuws',
    from: 'Nieuws van {date}',
    more: 'Laad meer nieuws',
  },
  malfunctions: {
    title: 'Storingen',
    dashboard: 'Dashboardlampjes',
    description: 'Storing of brandend dashboardlampje? Tik op een icoon om te kijken wat dit betekend.',
  },
  claim_form: {
    title: 'Schadeformulier',
    description: 'Geen formulieren meer invullen of opsturen. Door digitaal jouw schade te melden handel je dit direct af met jouw verzekeraar of leasemaatschappij.',
    open: 'Schadeformulier openen',
  },
  reviews: {
    title: 'Klantbeoordelingen',
    our_score: 'Onze beoordeling',
    average_rating: 'gemiddeld uit {n} beoordelingen',
    average_12_months_rating: 'gemiddeld afgelopen jaar',
    number_of_reviews_12_months: 'reviews afgelopen jaar',
    percentage_recommended: 'aangeraden door onze klanten',
    opinion: 'Geef uw mening',
    description: 'Wij vinden het altijd leuk om reacties te ontvangen van onze klanten.',
    description_2: 'Bent u tevreden en wilt u dat laten weten?',
    reviews: 'beoordelingen',
    average: 'gemiddeld',
    author: '{name} uit {city} op {date}',
    recommended: 'Aanbevolen',
    more: 'Laad meer beoordelingen',
  },
  profile: {
    title: 'Mijn profiel',
    heading: 'Mijn gegevens en voorkeuren',
    data: 'Gegevens',
    preferences: 'Voorkeuren',
    my_preferences: 'Mijn voorkeuren',
    message_categories: 'Bericht categorieën',
    message_category_general: 'Algemeen',
    message_category_service: 'Service',
    message_category_marketing: 'Marketing',
    message_category_personal: 'Persoonlijk',
    message_channels: 'Bericht kanalen',
    message_channel_post: 'Post',
    message_channel_phone: 'Telefoon',
    message_channel_mobile: 'Mobiel',
    message_channel_sms: 'SMS',
    message_channel_whatsapp: 'WhatsApp',
    message_channel_email: 'E-mail',
    preferences_thanks: 'Voorkeuren succesvol opgeslagen',
  },
  cars: {
    title: "Mijn auto's",
    add_car: 'Auto toevoegen',
    add_description: 'Vul hier je kenteken in. Hiermee kunnen we zien in welke auto je rijdt en jou automatische berichten sturen om je bijvoorbeeld te herinneren aan de keuringsdatum.',
    add_no_results: 'Geen auto\'s gevonden.',
  },
  car: {
    title: 'Mijn auto',
    data: 'Gegevens',
    edit: 'Gegevens aanpassen',
    delete: 'Verwijderen uit profiel',
    inspection_expiration_date: 'Keuringsdatum',
    buildyear: 'Bouwjaar',
    mileage: 'Kilometerstand (laatst bekend)',
    fuel_type: 'Brandstof',
    color: 'Kleur',
    lease: 'Lease',
    tires: 'Banden',
    tires_summer: 'Zomer',
    tires_winter: 'Winter',
    tires_summer_winter: 'Zomer- en winter',
    tires_all_season: 'All-season',
    thanks: 'Uw gegevens zijn opgeslagen',
  },
  install: {
    title: 'Installeer onze (web)app',
    click: 'Klik op het',
    description: 'icoon onderaan je scherm, scroll naar beneden tot je “Zet op beginscherm” ziet, en klik op Toevoegen.',
    chrome: 'Gebruik de Safari browser om deze app op je beginscherm te installeren.',
    skip: 'Of ga verder zonder te installeren...',
  },
  errors: {
    network: 'Er is een netwerkfout opgetreden',
  },
};
